import { createUserManager } from 'redux-oidc';

const domain = 'cmrclaims.com';
const host = `${window.location.protocol}//${window.location.hostname}${
  window.location.port ? `:${window.location.port}` : ''
}`;

const authority = process.env.REACT_APP_AUTH_URL;

const oidcSettings = {
  client_id: 'Customer.Portal',
  redirect_uri: `${host}/callback`,
  response_type: 'token id_token',
  scope:
    'openid profile email cmr.profile cmr.customerportal Cmr.CustomerPortal.Api dsxqi-api',
  authority: authority,
  silent_redirect_uri: `${host}/silent-renew`,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
  monitorSession: false,
};

var manager = createUserManager(oidcSettings);
export default manager;
