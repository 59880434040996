import React, { useState, useEffect } from 'react';
import { CallbackComponent, processSilentRenew } from 'redux-oidc';
import { push as pushFunc } from 'connected-react-router';
import { connect } from 'react-redux';
import userManager from './userManager';
import { ReactComponent as Logo } from '../Layout/logo.svg';
import { Loading } from '../../Components';

const doLogin = () => {
  userManager.getUser().then((response) => {
    if (response && !response.expired) return;

    userManager.signinRedirect().catch((reason) => {
      setTimeout(doLogin, 2000);
    });
  });
};

const Auth = ({ location, hasLoggedOut, push }) => {
  const [render, setRender] = useState(false);

  useEffect(() => {
    if (location === '/silent-renew') {
      processSilentRenew();
    } else {
      if (location !== '/callback' && !hasLoggedOut) {
        doLogin();
      }
    }

    const renderDelay = setTimeout(() => setRender(true), 500);

    return () => {
      clearTimeout(renderDelay);
    };
  }, [location, hasLoggedOut]);

  if (!render) return null;

  if (location === '/callback') {
    return (
      <CallbackComponent
        userManager={userManager}
        successCallback={(e, r, s) => {
          push('/');
        }}
        errorCallback={(e, r, s) => {
          push('/');
        }}
      >
        <Loading
          size='large'
          className='mt-5 text-center'
          text='Initializing'
        />
      </CallbackComponent>
    );
  }

  console.error('location != /callback', location);
  return (
    <div className='container pt-5'>
      <div className='mt-5 text-center'>
        <Logo className='w-50 mx-auto' />
        <Loading
          size='large'
          className='mt-5 text-center'
          text='Authenticating'
        />
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    location: state.router.location.pathname,
    hasLoggedOut: state.oidc.hasLoggedOut,
  }),
  { push: pushFunc },
)(Auth);
