import React from 'react';
import { httpFactory } from '../httpFactory';
import { getName } from '../Common/FileUtils';
import { Loading } from './StatusDisplay';

export class SecureImage extends React.Component {
  state = {
    objectUrl: null,
  };
  componentDidMount() {
    this.loadImage();
  }
  componentDidUpdate(prevProps) {
    if (this.props.src && this.props.src !== prevProps.src) this.loadImage();
  }
  loadImage() {
    if (!this.props.src) return;

    httpFactory()
      .get(this.props.src, { responseType: 'blob' })
      .then((response) => {
        this.setState({
          objectUrl: window.URL.createObjectURL(new Blob([response.data])),
        });
      });
  }
  unloadImage = () => {
    window.URL.revokeObjectURL(this.state.objectUrl);
  };
  render() {
    if (!this.state.objectUrl) return <Loading className='text-center mt-2' />;

    return (
      <img
        {...this.props}
        src={this.state.objectUrl}
        onLoad={this.unloadImage}
        alt={this.props.alt || getName(this.props.src)}
      />
    );
  }
}
