import React from 'react';
import { withFormsy } from 'formsy-react';
import { Col, FormGroup, Input, FormFeedback, Label } from 'reactstrap';
import moment from 'moment';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

class MyInput extends React.Component {
  changeValue = (event) => {
    let value = event.currentTarget
      ? event.currentTarget.value
      : event.target.value;

    if (this.props.type === 'date') {
      value = moment(value);
    }

    if (this.props.setValue) {
      this.props.setValue(value);
    }

    if (this.props.onChange) {
      this.props.onChange(event.target.name, value);
    }
  };
  onSelectChange = (selectedVal) => {
    if (this.props.onChange) {
      this.props.onChange(this.props.name, selectedVal);
    }
  };
  render() {
    var isFormsy = this.props.isValid;
    const isValid = isFormsy && this.props.isValid;
    const errorMessage = isFormsy && this.props.getErrorMessage;
    const className =
      !isFormsy || (this.props.isFormSubmitted && isValid) ? '' : 'has-error';
    let value =
      (this.props.getValue ? this.props.getValue : this.props.value) || '';

    if (this.props.type === 'date' && value) {
      value = moment(value).format('YYYY-MM-DD');
    }

    if (this.props.type === 'time' && value)
      value = moment(value).format('HH:mm');

    let input;

    if (this.props.type === 'select') {
      const className = this.props.className
        ? `${this.props.className} select-input`
        : 'select-input';

      if (this.props.loadOptions) {
        input = (
          <AsyncSelect
            name={this.props.name}
            value={value}
            onChange={this.onSelectChange}
            id={this.props.id}
            valid={isValid}
            invalid={!isValid}
            isDisabled={this.props.disabled}
            className={className}
            classNamePrefix='select-input'
            placeholder={this.props.placeholder}
            cacheOptions
            loadOptions={this.props.loadOptions}
            isMulti={this.props.isMulti}
            isClearable={!this.props.required}
          />
        );
      } else {
        input = (
          <Select
            name={this.props.name}
            value={value}
            onChange={this.onSelectChange}
            options={this.props.options}
            id={this.props.id}
            valid={isValid}
            invalid={!isValid}
            isDisabled={this.props.disabled}
            className={className}
            classNamePrefix='select-input'
            placeholder={this.props.placeholder}
            isMulti={this.props.isMulti}
            isClearable={!this.props.required}
          />
        );
      }
    }
    if (!input)
      input = (
        <Input
          onChange={this.changeValue}
          bsSize={this.props.bsSize}
          value={value}
          id={this.props.id}
          valid={isValid}
          invalid={!isValid}
          disabled={this.props.disabled}
          name={this.props.name}
          type={this.props.type}
          rows={this.props.rows}
          plaintext={this.props.plaintext}
          placeholder={this.props.placeholder}
          className={this.props.inputClassName}
        >
          {this.props.children}
        </Input>
      );

    if (this.props.noFormGroup) return input;

    if (this.props.labelWidth) {
      return (
        <FormGroup className={className} row>
          {this.props.label && (
            <Label sm={this.props.labelWidth} for={this.props.id}>
              {this.props.label}
            </Label>
          )}
          <Col sm={12 - this.props.labelWidth}>
            {input}
            {errorMessage && <FormFeedback>{errorMessage}</FormFeedback>}
          </Col>
        </FormGroup>
      );
    }

    return (
      <FormGroup className={className}>
        {this.props.label && (
          <Label for={this.props.id}>{this.props.label}</Label>
        )}
        {input}
        {errorMessage && <FormFeedback>{errorMessage}</FormFeedback>}
      </FormGroup>
    );
  }
}

const FormsyInput = withFormsy(MyInput);
export { FormsyInput, MyInput as Input };
