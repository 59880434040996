import { reducer as oidcReducer } from 'redux-oidc';

const initState = {
  user: null,
  isLoadingUser: false,
  hasLoggedOut: false,
};

export default function(state = initState, action) {
  let hasLoggedOut = state.hasLoggedOut;

  if (action.type === 'redux-oidc/SESSION_TERMINATED') {
    hasLoggedOut = true;
  }

  return {
    ...oidcReducer(state, action),
    hasLoggedOut,
  };
}
