import React, { Component } from 'react';
import { Col } from 'reactstrap';
import { Address } from './Address';
import { PhoneDisplay } from './Phone';
import { CurrencyDisplay } from './Currency';
import { DateDisplay } from './DateDisplay';

export class DisplayField extends Component {
  render() {
    let displayValue = this.props.value;

    if (displayValue && displayValue.hasOwnProperty('name'))
      displayValue = displayValue.name;

    let display = displayValue;

    if (
      (!display && display !== false) ||
      (Object.entries(display).length === 0 && isNaN(display))
    ) {
      display = <span className='text-muted'>Not Available</span>;
    } else {
      switch (this.props.type) {
        case 'date':
          display = <DateDisplay>{display}</DateDisplay>;
          break;
        case 'address':
          display = <Address value={display} />;
          break;
        case 'phone':
          display = <PhoneDisplay value={display} />;
          break;
        case 'currency':
          display = <CurrencyDisplay>{display}</CurrencyDisplay>;
          break;
        default:
          if (typeof display === 'object')
            display = <span className='text-muted'>Invalid Value</span>;
      }
    }

    const isBool = display === true || display === false;

    if (isBool)
      return (
        <Col xs={6} sm={3} className='info-field'>
          <strong>{`${this.props.label}?`}</strong>
          <br />
          {display === true && 'Yes'}
          {display === false && 'No'}
        </Col>
      );

    return (
      <Col xs={6} sm={3} className='info-field'>
        <strong>{this.props.label}</strong>
        <br />
        {display}
      </Col>
    );
  }
}
