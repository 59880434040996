import React, { Component } from 'react';
import { connect } from 'react-redux';
import Layout from './Layout/Layout';
import Routes from './Routes';
import Auth from './Auth/Auth';

class App extends Component {
  render() {
    if (!this.props.user) return <Auth />;

    return (
      <Layout>
        <Routes />
      </Layout>
    );
  }
}

export default connect((state) => ({
  location: state.router.location.pathname,
  user: state.oidc.user,
}))(App);
