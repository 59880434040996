import React from 'react';
import Loadable from 'react-loadable';
import { Loading } from '../Components/StatusDisplay';

export default (importFunc) =>
  Loadable({
    loader: importFunc,
    loading(props) {
      if (props.pastDelay) return <Loading size='large' center />;
      return null;
    },
  });
