import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import LookupBox from '../../Claims/ClaimNumberLookup/LookupBox';
import UserDisplay from '../User/UserDisplay';

import { ReactComponent as Logo } from './logo.svg';

import { NavLink as Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Header extends React.Component {
  state = {
    isOpen: false,
  };
  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };
  collapse = () => {
    if (window.innerWidth <= 768)
      this.setState({
        isOpen: false,
      });
  };
  render() {
    const { user, client } = this.props;

    const showReports =
      user.profile &&
      user.profile.client_report &&
      user.profile.client_report.length > 0;
    const showFiles = client && client.hasFiles;

    return (
      <div>
        <Navbar expand='md' className='border-bottom' fixed='top'>
          <NavbarBrand
            to='/'
            tag={(props) => <Link {...props} />}
            className='mx-3'
          >
            <Logo style={{ width: '150px', height: '36px' }} />
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle}>
            <FontAwesomeIcon icon={['fal', 'bars']} />
          </NavbarToggler>
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav navbar className='mr-auto navbar-primary'>
              <NavItem>
                <NavLink
                  tag={(props) => <Link {...props} />}
                  to='/'
                  exact
                  onClick={this.collapse}
                >
                  Claims
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  tag={(props) => <Link {...props} />}
                  to='/Metrics'
                  onClick={this.collapse}
                >
                  Dashboard
                </NavLink>
              </NavItem>
              {showFiles && (
                <NavItem>
                  <NavLink
                    tag={(props) => <Link {...props} />}
                    to='/Files'
                    onClick={this.collapse}
                  >
                    Archives
                  </NavLink>
                </NavItem>
              )}
              {showReports && (
                <NavItem>
                  <NavLink
                    tag={(props) => <Link {...props} />}
                    to='/Reports'
                    onClick={this.collapse}
                  >
                    Reports
                  </NavLink>
                </NavItem>
              )}
            </Nav>
            <LookupBox />
            <Nav navbar className='navbar-secondary'>
              <NavItem>
                <UserDisplay />
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

const connectWithRouter = compose(
  withRouter,
  connect((state) => ({
    user: state.oidc.user || {},
    client: state.clients.selected,
  })),
);

export default connectWithRouter(Header);
