import React from 'react';
import { Route, Switch } from 'react-router';
import dynamicLoadable from '../Common/dynamicImport';

//Code splitting(via dynamic importing) allows the app to load faster as each dynamic
// component and it's dependencies aren't loaded till needed.
//https://serverless-stack.com/chapters/code-splitting-in-create-react-app.html
const ClaimSearch = dynamicLoadable(() =>
  import('../Claims/Search/ClaimSearch'),
);
const ClaimView = dynamicLoadable(() => import('../Claims/View/ClaimView'));
const Metrics = dynamicLoadable(() => import('../Metrics/Metrics'));
const Files = dynamicLoadable(() => import('../Files/Files'));
const Reports = dynamicLoadable(() => import('../Reports/Reports'));

export default () => (
  <Switch>
    <Route exact={true} path='/' component={ClaimSearch} />
    <Route path='/Metrics' component={Metrics} />
    <Route path='/Files' component={Files} />
    <Route path='/Reports/:path?' component={Reports} />
    <Route path='/Claim/:source/:id' component={ClaimView} />
  </Switch>
);
