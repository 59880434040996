import React, { Fragment } from 'react';
import { Label, FormGroup } from 'reactstrap';
import NumberFormat from 'react-number-format';
import { FormsyNumericInput } from './Numeric';
import { isEqual } from 'lodash-es';

const blankPhone = {
  number: '',
  extension: '',
};

export class PhoneDisplay extends React.Component {
  render() {
    const value =
      this.props.value && (this.props.value.number || this.props.value);

    if (!value) {
      if (this.props.renderNullBlank) return null;

      return <span className='text-muted'>Not Specified</span>;
    }

    return (
      <Fragment>
        <NumberFormat
          {...this.props}
          format='(###) ###-####'
          displayType='text'
          value={value}
        />
        {this.props.value.extension && (
          <span> x {this.props.value.extension}</span>
        )}
      </Fragment>
    );
  }
}

export class PhoneInput extends React.Component {
  state = {};
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.value && nextProps.value !== prevState)
      return nextProps.value;

    return null;
  }
  onChange = (field, value) => {
    field = field.replace(`${this.props.name}_`, '');
    const updatedPhone = { ...this.state, [field]: value };
    this.props.onChange(this.props.name, updatedPhone);
    this.setState(updatedPhone);
  };
  render() {
    const phone =
      this.state && !isEqual(this.state, {}) ? this.state : blankPhone;

    return (
      <FormGroup>
        <Label>{this.props.label}</Label>
        <div className='form-phone d-flex'>
          <FormsyNumericInput
            format='(###) ###-####'
            mask='_'
            name={`${this.props.name}_number`}
            value={phone.number}
            onChange={this.onChange}
            className={this.props.hideExtension ? '' : 'w-65 border-right-0'}
            noFormGroup
          />
          {!this.props.hideExtension && (
            <FormsyNumericInput
              name={`${this.props.name}_extension`}
              format='x #####'
              value={phone.extension}
              onChange={this.onChange}
              className='w-35'
              placeholder='x'
              noFormGroup
            />
          )}
        </div>
      </FormGroup>
    );
  }
}
