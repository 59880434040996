import { isSuccessAction } from '../Common/ReducerHelpers';
import { actions } from './MetricActions';
import { actions as clientActions } from '../Clients/ClientActions';
import { startsWith } from 'lodash-es';

const initState = {
  recoveries: {},
  referrals: {},
  keyMetrics: {},
};

export default function(state = initState, action) {
  if (action.type === clientActions.actionNames.select) {
    return { ...initState };
  }

  if (
    isSuccessAction(action) &&
    startsWith(action.type, actions.actionNames.load)
  ) {
    var segments = action.type.split('.');
    const period = segments[segments.length - 1];
    const dataSet = segments[segments.length - 2];

    const result = {
      ...state,
      [dataSet]: {
        ...state[dataSet],
        [period]: action.payload,
      },
    };

    if (
      period === 'mtd' &&
      dataSet === 'recoveries' &&
      action.payload.length > 0
    ) {
      result.averageMonthlyRecoveries = action.payload[0].c[3].v;
    }

    return result;
  }
  return state;
}
