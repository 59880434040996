import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import status from './App/Status/StatusReducer';
import clients from './Clients/ClientsReducer';
import claimSearch from './Claims/Search/ClaimSearchReducer';
import claimView from './Claims/View/ClaimViewReducer';
import files from './Files/FilesReducer';
import metrics from './Metrics/MetricReducers';
import oidc from './App/Auth/oidcReducer';

export default (history) =>
  combineReducers({
    clients,
    status,
    claimSearch,
    claimView,
    files,
    metrics,
    oidc,
    router: connectRouter(history),
  });
