import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { StatusDisplay } from '../../Components';
import Header from './Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './layout.scss';

class Layout extends Component {
  render() {
    return (
      <Fragment>
        <Header />
        <div className='content-wrapper my-5 pt-5'>
          <StatusDisplay
            status={this.props.status}
            size='large'
            loadingClassName='mt-5 text-center'
            loadingText='Initializing'
          >
            {this.props.children}
          </StatusDisplay>
        </div>
        <footer className='footer bg-dark text-center pt-5 pb-3'>
          <h4>
            Expect
            <strong>Success</strong>
          </h4>
          <div className='divider' />
          <ul className='footer-links'>
            <li>
              <a
                href='https://cmrclaims.com/contact'
                target='_blank'
                rel='noopener noreferrer'
              >
                <FontAwesomeIcon icon={['fas', 'mobile-alt']} size='3x' />
                <span>Contact</span>
              </a>
            </li>
            <li>
              <a
                href='https://cmrclaims.com/about'
                target='_blank'
                rel='noopener noreferrer'
              >
                <FontAwesomeIcon icon={['fas', 'building']} size='3x' />
                <span>About Us</span>
              </a>
            </li>
            <li>
              <a
                href='https://cmrclaims.com/faq'
                target='_blank'
                rel='noopener noreferrer'
              >
                <FontAwesomeIcon icon={['fas', 'question-circle']} size='3x' />
                <span>FAQ</span>
              </a>
            </li>
          </ul>
          <p>
            <a
              href='https://cmrclaims.com/legal'
              target='_blank'
              rel='noopener noreferrer'
            >
              Legal Disclaimer
            </a>
            - Copyright &copy;
            {new Date().getFullYear()} CMR. All rights reserved.
          </p>
        </footer>
      </Fragment>
    );
  }
}

export default connect((state) => ({
  status: state.status.clients.load,
}))(Layout);
