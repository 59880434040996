import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export class CheckboxDisplay extends React.Component {
  render() {
    return (
      <label className={this.props.className}>
        {this.props.checked && <FontAwesomeIcon icon={['fal', 'check']} />}
        {this.props.label && ' ' + this.props.label}
      </label>
    );
  }
}

export class Checkbox extends React.Component {
  onChange = (event) => {
    if (this.props.onChange) {
      this.props.onChange(event.target.name, event.target.checked);
    }

    this.setState({ checked: event.target.checked });
  };
  render() {
    const checked = this.props.checked || false;
    return (
      <label className='font-weight-normal'>
        <FontAwesomeIcon
          icon={['fal', checked ? 'check-square' : 'square']}
          className='mx-1'
          size='lg'
        />
        <input
          type='checkbox'
          id={this.props.id}
          name={this.props.name}
          className='custom-control-input'
          checked={checked}
          onChange={this.onChange}
          disabled={this.props.disabled}
        />
        {this.props.label && ' ' + this.props.label}
      </label>
    );
  }
}
