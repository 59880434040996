import { convertParamsToQueryString, httpFactory } from './httpFactory';

export class Api {
  constructor(controllerName) {
    this.controllerName = controllerName;
  }
  add = (data) => httpFactory().post(this.controllerName, data);
  update = (data) => httpFactory().put(this.controllerName, data);
  delete = (id) => httpFactory().delete(`${this.controllerName}/${id}`);
  load = (id) => {
    let url = this.controllerName;

    if (id) url += `/${id}`;

    return httpFactory().get(url);
  };
  post = (actionName, data) =>
    httpFactory().post(`${this.controllerName}/${actionName}`, data);
  get = (actionName, params) => {
    let url = this.controllerName;

    if (actionName) url = `${url}/${actionName}`;

    if (params) {
      url += `?${convertParamsToQueryString(params)}`;
    }

    return httpFactory().get(url);
  };
  put = (actionName, data) =>
    httpFactory().put(`${this.controllerName}/${actionName}`, data);
}
