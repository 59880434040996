import { apiAction } from '../Common/ActionHelpers';
import { Api } from '../api';

const namespace = 'metrics';

export const actions = {
  actionNames: {
    load: `${namespace}.load`,
  },
  loadIfNeeded: (dataSet, period) => async (dispatch, getState) => {
    if (!getState().metrics[dataSet][period])
      dispatch(actions.load(dataSet, period));
  },
  load: (dataSet, period) => async (dispatch, getState) => {
    const client = getState().clients.selected;

    if (!client) return;

    return await apiAction(
      `${actions.actionNames.load}.${dataSet}.${period}`,
      () => {
        const api = new Api('Metrics');
        return api.get(`${client.shortName}/${dataSet}/${period}`);
      },
    );
  },
};
