import React from 'react';
import ReactDOM from 'react-dom';
import App from './App/App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import initIconLibrary from './IconLibrary';
import { OidcProvider } from 'redux-oidc';
import 'react-dates/initialize';
import userManager from './App/Auth/userManager';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { store, history } from './App/configureStore';

// console.log('REACT_APP_NODE_ENV', process.env.REACT_APP_NODE_ENV);
// console.log('REACT_APP_AUTH_URL', process.env.REACT_APP_AUTH_URL);

initIconLibrary();

const rootElement = document.getElementById('root');
const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <OidcProvider store={store} userManager={userManager}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </OidcProvider>
    </Provider>,
    rootElement,
  );
};
render();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
