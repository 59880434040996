import moment from 'moment';
import { set, get, cloneDeep } from 'lodash-es';

export const initStatus = {
  loading: false,
  error: null,
  success: false,
  result: null,
  queued: false,
  lastChanged: null,
  percentCompleted: 0,
};

const initialState = {
  app: {
    init: { ...initStatus },
  },
  clients: {
    load: { ...initStatus },
  },
  claimSearch: {
    load: { ...initStatus },
    download: { ...initStatus },
  },
  claimView: {
    load: { ...initStatus },
  },
  files: {
    load: { ...initStatus },
  },
  metrics: {
    load: {},
  },
};

export default function(state = initialState, action) {
  if (!action.status) {
    return state;
  }

  const output = cloneDeep(state);
  let node = get(output, action.type);

  switch (action.status) {
    case 'reset':
      node = cloneDeep(get(initialState, action.type));
      break;
    case 'success':
      node = {
        ...node,
        loading: false,
        error: null,
        success: true,
        queued: false,
        result: action.payload,
        lastChanged: moment(),
      };
      break;
    case 'error':
      let message;

      if (action.error && action.error.response) {
        message = action.error.response.data.message;
      }

      node = {
        ...node,
        loading: false,
        error: message || 'An error occured',
        success: false,
        queued: false,
        result: null,
        lastChanged: moment(),
      };
      break;
    case 'queued':
      node = {
        ...node,
        loading: false,
        error: null,
        success: false,
        queued: true,
        result: null,
        lastChanged: moment(),
        percentCompleted: 0,
      };
      break;
    case 'processing':
      node = {
        ...node,
        loading: true,
        error: null,
        success: false,
        queued: false,
        result: null,
        lastChanged: moment(),
        percentCompleted: action.percentCompleted || 0,
      };
      break;
    default:
  }

  set(output, action.type, node);

  return output;
}
