import { apiAction } from '../Common/ActionHelpers';
import { Api } from '../api';

const namespace = 'files';

export const actions = {
  actionNames: {
    load: `${namespace}.load`,
    filesPageChange: `${namespace}.files.pageChange`,
    filesFilterChange: `${namespace}.files.filterChange`,
    filesLocationChange: `${namespace}.files.locationChange`,
  },
  filesPageChange: (newIndex) => ({
    type: actions.actionNames.filesPageChange,
    newIndex,
  }),
  filesFilterChange: (filter) => ({
    type: actions.actionNames.filesFilterChange,
    filter,
  }),
  filesLocationChange: (location) => ({
    type: actions.actionNames.filesLocationChange,
    location,
  }),
  load: () => async (dispatch, getState) => {
    const client = getState().clients.selected;

    if (!client) return;

    return await apiAction(actions.actionNames.load, () => {
      const api = new Api('Files');
      return api.get(client.shortName);
    });
  },
};
